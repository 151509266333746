import logo from './logo.svg';
import './App.css';
import AppContext from './appContext';
import { RouterProvider } from 'react-router-dom';
import router from './router';

function App() {
  return (
    <AppContext>
      <RouterProvider router={router} />
    </AppContext>
  );
}

export default App;
