import { createContext } from "react"

export const appContext = createContext()

export default function AppContext({children}){
    return (
        <appContext.Provider>
            {children}
        </appContext.Provider>
    )
}