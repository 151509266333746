import { Link } from "react-router-dom";
import logo from '../Assets/logo.png'

export default function Footer(){
    return (
        <div className=''>
            <div className='grid grid-cols-4 max-md:grid-cols-2 max-md:gap-10 bg-black p-8'>

                <div className='flex flex-col gap-4'>
                    <h2 className='text-white text-[24px]'>24/7 Prayer</h2>
                    <div className='text-white text-sm flex flex-col gap-1 opacity-[0.8]'>
                        <Link>Prayer Request</Link>
                        <Link>Find A Mentor</Link>
                        <Link>Become A Prayer Partner</Link>
                    </div>
                </div>

                <div className='flex flex-col gap-4'>
                    <h2 className='text-white text-[24px]'>About</h2>
                    <div className='text-white text-sm flex flex-col gap-1 opacity-[0.8]'>
                        <Link>History</Link>
                        <Link>Our Team</Link>
                        <Link>Cotact</Link>
                    </div>
                </div>

                <div className='flex flex-col gap-4'>
                    <h2 className='text-white text-[24px]'>Contact</h2>
                    <div className='text-white text-sm flex flex-col gap-1 opacity-[0.8]'>
                        <h3>Bole road, AA</h3>
                        <p>Block/1 B-200</p>
                    </div>
                </div>

                <div className='flex flex-col gap-4'>
                    <img src={logo} className='w-[120px]' />
                    <div className='text-white text-sm flex flex-col gap-1'>
                        <p className='opacity-[0.8]'>Yenefs kurs donations will go directly <br /> to the assigned organizations</p>
                    </div>
                </div>

            </div>

            <div className='bg-black border-t-[1px] border-t-[#ffffff50] text-white text-sm p-4'>
                <p className='text-center'>&#169; Yenefs Qurs </p>
            </div>
        </div>
    )
}