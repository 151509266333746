import slide from '../Assets/slide.PNG'
import {Loader} from 'react-feather'
import prayer from '../Assets/prayer.png'
import callCenter from '../Assets/callcenter.PNG'
import img1 from '../Assets/IMG_1.png'
import img2 from '../Assets/IMG_2.png'
import img3 from '../Assets/IMG_3.png'
import img4 from '../Assets/IMG_4.png'
import about from '../Assets/about.JPG'
import { useState } from 'react'
import axios from 'axios'
import { baseUrl, path } from '../constants'
import { Link } from 'react-router-dom'

export default function Home(){
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})

    const sendRequest = (e) => {
        e.preventDefault()
        setLoading(true)
        axios.post(baseUrl + path.createRequest, {
            requester_name: e.target.fn.value,
            contact: e.target.contact.value,
            request: e.target.request.value,
        }).then(response => {
            if(response.data.success){
                setMessage({content: 'Request submitted!', success: true})
                e.target.reset()
            } else {
                setMessage({content: 'Error has been occured!'})
            }
        }).catch(e => setMessage({content: 'Error has been occured!'}))
        .finally(() => setLoading(false))
    }

    return (
        <div className='flex flex-col'>
            <div className='relative'>
                <div className='absolute inset-0 bg-[#00000070] flex flex-col justify-center gap-6 pl-[100px] overflow-hidden max-md:pl-[50px]'>
                    <h1 className='text-[80px] text-white animate-bottom max-md:text-[40px]'>የነፍስ ቁርስ</h1>
                    <a href="#prayer-request" className='bg-yellowish z-[999] rounded-md shadow-lg px-10 py-3 text-greenish text-[22px] font-bold self-start'>የጸሎት ርእስ አሎት?</a>
                </div>
                <div style={{backgroundImage: `url(${slide})`}} className='bg-no-repeat bg-cover bg-center w-full h-[500px] object-cover'></div>
            </div>

            <div className='bg-[#fbebe5] flex justify-between p-6 justify-around max-md:flex-col max-md:items-center max-md:gap-8'>
                <div className='flex flex-col gap-6 items-center'>
                    <img src={prayer} className='w-[150px] object-contain' />
                    <p>24/7 Prayer</p>
                    <p className='text-center'>Prayer care for anyone, <br /> anywhere, anytime.</p>
                    <button className='bg-yellowish rounded-md shadow-lg px-8 py-3 text-greenish text-[18px] font-bold self-start'>Become a Volunteer</button>
                </div>

                <img className='w-[300px] rounded-lg shadow-md object-cover' src={callCenter} />
            </div>
            <div className='p-12 bg-greenish'>
                <h1 className='text-white text-[30px] mb-12'>Explore Our Shows</h1>
                <div className='grid grid-cols-4 gap-4 max-md:grid-cols-1'>
                    <div className='p-4 pb-0 bg-[#fbebe5] rounded-[30px]'>
                        <img src={img1} className='w-full rounded-[30px] h-[200px] object-cover' />
                        <a href='#' className='text-[28px] text-greenish my-6 block text-center w-full'>Watch Now</a>
                    </div>
                    <div className='p-4 pb-0 bg-[#fbebe5] rounded-[30px]'>
                        <img src={img2} className='w-full rounded-[30px] h-[200px] object-cover' />
                        <a href='#' className='text-[28px] text-greenish my-6 block text-center w-full'>Watch Now</a>
                    </div>
                    <div className='p-4 pb-0 bg-[#fbebe5] rounded-[30px]'>
                        <img src={img3} className='w-full rounded-[30px] h-[200px] object-cover' />
                        <a href='#' className='text-[28px] text-greenish my-6 block text-center w-full'>Watch Now</a>
                    </div>
                    <div className='p-4 pb-0 bg-[#fbebe5] rounded-[30px]'>
                        <img src={img4} className='w-full rounded-[30px] h-[200px] object-cover' />
                        <a href='#' className='text-[28px] text-greenish my-6 block text-center w-full'>Watch Now</a>
                    </div>
                </div>
            </div>

            <div className='flex gap-6 px-6 py-20 max-md:flex-col max-md:items-center'>
                <img src={about} className='flex-1 h-[600px] object-contain' />
                <div className='flex-1'>
                    <h1 className='text-[30px] text-center'>ራዕይ</h1>
                    <p className='mt-5 text-center'>
                        አፋጣኝ መንፈሳዊ ድጋፍ የሚገኝበት መንገድ ለመፍጠር።
                    </p>
                    <h1 className='text-[30px] mt-8 text-center'>ተልዕኮ</h1>
                    <p className='mt-5 text-center'>
                    መንፈሳዊ መመሪያ ለሚፈልጉ ግለሰቦች ፈጣን እና ሚስጥራቸውን በጠበቀ መልኩ የጸሎት እና የምክር አገልግሎት ለመስጠት የ24 ሰአት የጸሎት ጥሪ ማእከልን ማቋቋም ነው።
                    </p>
                    <p className='mt-8'>
                    • ለ 1 ጊዜ ብቻ ሳይሆን በቋሚነት መንፈሳዊ ትምህርት እና ጸሎት የሚገኝበትን መንገድ መዘርጋት። <br />
                    • ለግለሰቦች ሸክማቸውን እንዲያካፍሉ እና መጽናናትን እንዲያገኙ ደህንነቱ የተጠበቀ እና የሚጋብዝ አገልግሎትን መፍጠር። <br />
                    • አስተማሪ እና አነቃቂ ይዘቶችን ለመካፈል ማህበራዊ ሚዲያን መጠቀም፣ የመጽሐፍ ቅዱስ ጥናቶችን፣ ምስክርነቶችን እና የስነ-ልቦና ምክሮችን ጨምሮ።<br />
                    • ውጤታማ እና ርህራሄ ያለው ድጋፍ ለመስጠት የሚያስፈልጉትን ስልጠናዎች እና ግብአቶች በስልክ ጥሪው ላይ ለሚሳተፉ አገልጋዮች ማቅረብ።
                    </p>
                </div>
            </div>
            <section id='prayer-request'>
            <form  onSubmit={sendRequest} className='bg-greenish text-white p-10 flex flex-col items-center gap-8'>
                <h1 className='text-[35px] font-bold'>Online Prayer Request</h1>
                <div className='flex gap-6'>
                    <div className='flex flex-col'>
                        <label className='text-[18px]' for='fn'>First Name</label>
                        <input id='fn' name='fn' className='p-4 mt-4 text-greenish' placeholder='Anonymous' />
                    </div>
                    <div  className='flex flex-col'>
                        <label for='text' className='text-[18px]'>Phone or Email</label>
                        <input name='contact' className='p-4 mt-4 text-greenish' id='text' placeholder='Phone or Email' required />
                    </div>
                </div>
                <div className='flex flex-col'>
                    <label for='text' className='text-[18px]'>Prayer Request</label>
                    <textarea name='request' className='p-4 mt-4 w-[450px] text-greenish' rows={4} required>

                    </textarea>
                </div>
                <button className='bg-yellowish ounded-md shadow-lg px-10 py-3 text-black text-[18px] font-bold'>
                    {loading ? <Loader className='text-greenish animate-spin' size={20} /> : 'Send Prayer Request'}
                </button>
                {message?.content && <p className={`py-2 px-6 rounded-md text-[17px] font-bold ${message.success ?'bg-[#00ff0040] text-[#00ff00]' : 'bg-[#ff000040] text-[#ff0000]'}`}>{message.content}</p>}
            </form>
        </section>
        </div>
    )
}