import { useState } from 'react'
import { Search, ChevronDown, Menu } from 'react-feather'
import { Link } from 'react-router-dom'
import logo from '../Assets/logo.png'

const links = [
    {title: 'Prayer Request',},
    {title: 'Prayer Volunteer'},
    {title: 'Find Mentor'},
    {title: 'Churches & Bible Studies', sub_links: [
        {title: 'Churches near you'},
        {title: 'Bible studies near you'}
    ]},
    {title: 'Counceling', sub_links: [
        {title: 'Marriage Counceling'},
        {title: 'Family Counceling'},
        {title: 'Health Counceling'}
    ]}
]

export default function Header(){
    const [showMenu, setShowMenu] = useState(false)
    return <>
        <div className='bg-[#00000099] px-6 py-2 flex items-center justify-between'>
            <div>
                <Link className='text-sm text-white'>Donate</Link>
                <Link className='text-sm text-white ml-4'>Prayer</Link>
            </div>
            <p className='text-sm text-white'> 24/7 Prayer Line 7777</p>
        </div>
        <div className='flex bg-greenish justify-between items-center py-3 px-6'>
            <div className='flex items-center gap-4'>
                <button onClick={() => setShowMenu(m => !m)}><Menu className='hidden max-md:block' size={25} color="#ffffff" /></button>
                <img src={logo} className='w-[80px]' />
            </div>
            <nav className={`flex items-center max-md:items-start max-md:p-4 gap-4 max-md:bg-greenish max-md:top-0 max-md:bottom-0 max-md:left-0 transition-all max-md:flex-col z-[99999] max-md:fixed ${!showMenu ? 'max-md:left-[-100%]' : 'max-md:left-0'}`}>
                {links.map(link => (
                    <div className='flex items-center gap-1 text-white group relative'>
                        <Link className={'text-[18px] font-[400] px-2 py-3 rounded-sm hover:bg-yellowish hover:text-greenish'}>{link.title}</Link>
                        {link.sub_links && <ChevronDown className='text-white group-hover:text-yellowish' size={18} />}
                        {link.sub_links && <div className='absolute hidden group-hover:flex top-[45px] w-fit flex-col bg-greenish z-[9999] flex-col gap-1 border-t-[2px] border-t-white'>
                            {link.sub_links.map( sub => <Link className={'text-[18px] whitespace-nowrap font-[400] text-white hover:bg-yellowish px-4 py-2 hover:text-greenish'}>{sub.title}</Link>)}
                        </div>}
                    </div>
            ))}
            </nav>
            {showMenu && <div className='fixed inset-0 bg-[#00000080] z-[9999]' onClick={() => setShowMenu(false)}></div>}
            <div className='flex items-center gap-4'>
                <button className='bg-yellowish rounded-md shadow-md px-6 py-2 text-greenish text-md font-bold'>Donate</button>
                <button className='bg-none border-none'><Search size={22} color={'#ffffff'} /></button>
            </div>
        </div>
    </>
}